import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

//Importa rotas
import routes from "./routes"
const router = new Router({ 
    mode: 'history',
    base: '',
    routes,
    scrollBehavior(to) {

        return new Promise((resolve) => {

            setTimeout(() => {

                if (to.hash) {
                    resolve({
                        selector: to.hash
                    });
                } else {
                    resolve({
                        x: 0,
                        y: 0
                    })
                }
            }, 200)


        })
    }
})

export default router