import Vue      from 'vue';
import Vuex     from 'vuex';

import m_auth   from './stores/auth';

Vue.use(Vuex);

const store = new Vuex.Store({ 
    modules: {
        auth : m_auth
    }
 });

export default store;