<template>
<div id="app" :class="'route-' + $route.name" >
    <router-view />
</div>
</template>

<script>
export default {
    name: 'App',
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s | Althoff Corrêa Advogados',
        meta: [{
            name: 'robots',
            content: 'index, follow',
        }]
    }
}
</script>

<style lang="scss">
@import "@sass/index.scss";
</style>
